import "./App.scss";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineInstagram } from "react-icons/ai";
import LogoImg from "./assets/ring.png";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaPinterest } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import JFLogo from "./assets/new_jf_logo.png";

function App() {
  return (
    <div className="App">
      <div className="logo_img">
        <img src={LogoImg} alt="" />
      </div>
      <div className="wordings">
        <p>Turning Lights on Soon</p>
      </div>
      <div className="links">
        <div className="svg_div">
          <a href="https://www.instagram.com/lite.lab/">
            <AiOutlineInstagram />
          </a>
        </div>
        <div className="svg_div">
          <a href="http://www.linkedin.com/in/litelab-india-b78729277">
            <FaLinkedinIn />
          </a>
        </div>
        <div className="svg_div">
          <a href="https://www.pinterest.com/litelabindia/_created">
            <FaPinterest />
          </a>
        </div>
        <div className="svg_div">
          <a href="https://www.facebook.com/profile.php?id=100092384953980">
            <FaFacebookF />
          </a>
        </div>
        <div className="svg_div">
          <a href="https://x.com/litelab_india">
            <FaXTwitter />
          </a>
        </div>
        <div className="svg_div">
          <a href="http://www.youtube.com/@litelab4250">
            <FaYoutube />
          </a>
        </div>
      </div>
      <div className="copyright">
        <p>Copyright &copy; Coming Soon 2025. All Rights Reserved</p>
        <p className="jf">
          Designed And Developed By{" "}
          <span>
            <a
              href="https://www.jollyfingers.com"
              target="_blank"
              alt=""
              rel="noopener noreferrer"
            >
              <img src={JFLogo} alt="JollyFingers" />
            </a>
          </span>
        </p>
      </div>
    </div>
  );
}

export default App;
